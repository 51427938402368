import React, { useEffect, useState } from 'react';
import { Routes, Route, Link, useParams } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>Welcome to React Router!</h1>
      <Routes>
        <Route path="/" element={<Posts />} />
        <Route path="posts">
          <Route path=":id" element={<Post />} />
        </Route>
      </Routes>
    </div>
  );
}

function Post({ id }) {
  const [post, setPost] = useState({});
  const params = useParams();

  useEffect(() => {

    const getPost = async () => {
      console.log(params.id);
      const resp = await fetch(`/api/posts/${params.id}`);

      const postResp = await resp.json();

      setPost(postResp);
    }

    getPost();
  }, [params]);

  if (!Object.keys(post).length) return <div />;

  return (
    <div>
      <h1>{post.title}</h1>
      <p>{post.text}</p>
      <p>
        <em>Published {new Date(post.published_at).toLocaleString()}</em>
      </p>
      <p>
        <Link to="/">Go back</Link>
      </p>
    </div>
  );
}

function Posts() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch('/api/posts');
      const postsResp = await resp.json();
      setPosts(postsResp);
    };

    getPosts();
  }, []);

  return (
    <div>
      <h1>Posts</h1>
      {posts.map(post => (
        <div key={post.id}>
          <h2>
            <Link to={`/posts/${post.id}`}>{post.title}</Link>
          </h2>
        </div>
      ))}
    </div>
  );
}

export default App;
